<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="12">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">WamAdmin</h1>
            <h4 class="pt-3">WamAdmin uygulama projeleri yönetim platformuna hoşgeldiniz...</h4>
            <p class="text-muted"></p>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col md="12">
          <b-link class="font-weight-bold font-xs btn-block text-muted" href="javascript:;" @click="f_goToAppManagePage()">Uygulama Proje Yönetim Sayfasına Git <i class="fa fa-angle-right float-right font-lg"></i></b-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Welcome',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {},
  props: {},
  data () {
    return {};
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_goToAppManagePage: function () {
      this.$router.push({ path: '/application', query: {} });
    },
  },
  watch: {}
}

</script>

